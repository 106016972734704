import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("brand/list"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    categoryBrandList(data) {
        return request({
            url: getUrl("categoryBrandRelation/list"),
            method: "post",
            data,
        })
    },
    catelogList(data) {
        return request({
            url: getUrl("categoryBrandRelation/list"),
            method: "post",
            data,
        })
    },
    brandsList(data) {
        return request({
            url: getUrl("categoryBrandRelation/brands/list/"+data),
            method: "get",
        })
    },
    catelogListTree(data) {
        return request({
            url: getUrl("category/list/tree"),
            method: "get",
            data,
        })
    },
    catelogDtl(data) {
        return request({
            url: getUrl("categoryBrandRelation/del/"+data),
            method: "post",
            data,
        })
    },
    catelogSave(data) {
        return request({
            url: getUrl("categoryBrandRelation/add"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("brand/update"),
            method: "post",
            data,
        })
    },
    updateStatus(data) {
        return request({
            url: getUrl("brand/update/status"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("brand/add"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("brand/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("brand/getInfo?id=" + data),
            method: "post",
        })
    },


}
