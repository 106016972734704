import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("advertising-strategy-relation/list"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("advertising-strategy-relation/add"),
            method: "post",
            data,
        })
    },
    bindLogistics(data) {
        return request({
            url: getUrl("advertising-strategy-relation/bindLogistics"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("advertising-strategy-relation/update"),
            method: "post",
            data,
        })
    },
    updateStatus(data) {
        return request({
            url: getUrl("advertising-strategy-relation/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("advertising-strategy-relation/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("advertising-strategy-relation/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("advertising-strategy-relation/listPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },

}
