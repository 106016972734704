import request from "@/utils/request";

function getUrl(url) {
    return "/shop-test/" + url;
}

export default {
    getInfo(data) {
        return request({
            url: getUrl("beverage/order/item/info/" + data),
            method: "get",
        })
    },
}
