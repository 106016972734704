<!--侧边栏菜单展示-->
<template>
    <div class="sidebar-container" style="padding: 0 0 80px;width: 170px;overflow: hidden">
        <div class="sidebar-avatar">
            <el-avatar size="large" fit="fill"
                       :src="dx"></el-avatar>
        </div>
        <el-scrollbar wrap-class="scrollbar-wrapper">
<!--            <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">-->
<!--                <el-radio-button :label="false">展开</el-radio-button>-->
<!--                <el-radio-button :label="true">收起</el-radio-button>-->
<!--            </el-radio-group>-->
            <el-menu text-color="#fff"
                     router
                     :default-active="currentPath"
                     active-text-color="#fff"
                     :unique-opened="true"
                     :router="true"
                     background-color="#202033" :collapse="isCollapse">
                <el-menu-item :index="user.accountType===999?'/dataBoard':'/index'">
                    <img src="https://railway-park.oss-cn-shanghai.aliyuncs.com/news/cover/2023/07/1690740540307.svg" style="width: 15px;height: 15px"/>
                    <span style="margin-left: 8px;">首页</span>
                </el-menu-item>
                <RecursiveMenu :menuData="getMenuTreeFilter"></RecursiveMenu>
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
    import RecursiveMenu from "@/ecareLay/compoments/Sidebar/RecursiveMenu";
    import {mapGetters} from "vuex";
    export default {
        components:{RecursiveMenu},
        name: "Sidebar",
        data() {
            return {
                isCollapse:false,
                dx: require('@/assets/img/logo.png'),
                activeIndex: 'index',
                query: {
                    tenantId: '01H39N1A1WWVZD0FT7X9EJHFYP',
                    userCustom: 'shop',
                },
                menus: [],
            }
        },
        created() {
            this.getMenuTree()
        },
        mounted() {
            // this.activeIndex = this.$route.path
            console.log("accountTypeaccountTypeaccountTypeaccountTypeaccountTypeaccountType",this.user.accountType)
        },
        // 在计算属性中获取去重后的对象数组
        computed: {
            ...mapGetters([
                'user',
            ]),
            getMenuTreeFilter() {
                const uniqueMap = {};
                const deduplicate = (data) => {
                    return data.filter(node => {
                        if (!uniqueMap[node.id]) {
                            uniqueMap[node.id] = true;
                            if (node.children && node.children.length > 0) {
                                node.children = deduplicate(node.children);
                            }
                            return true;
                        }
                        return false;
                    });
                };
                return deduplicate(this.menus);
            },
            currentPath() {
                return this.$route.path;
            }
        },
        methods: {
            getMenuTreeFilterss(item, prop) {
                const uniqueMap = {};
                const deduplicate = (data) => {
                    return data.filter(node => {
                        if (!uniqueMap[node.id]) {
                            uniqueMap[node.id] = true;
                            if (node.children && node.children.length > 0) {
                                node.children = deduplicate(node.children);
                            }
                            return true;
                        }
                        return false;
                    });
                };
                return deduplicate(this.originalTreeData);
            },
            getMenuTree() {
                this.$api.userAuth.getMenuTree(this.query).then(res => {
                    this.menus = res.result
                }).catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: error.msg
                    });
                })
            }
        }
    }
</script>

<style scoped>
    .sidebar-container {
        min-height: 100vh;
        width: 124px;
        background-color: #202033
    }

    .sidebar-avatar {
        height: 80px;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
    }

    .scrollbar-wrapper {
        overflow-x: hidden !important;
    }

    .el-menu-vertical:not(.el-menu--collapse) {
        width: 170px;
    }
</style>
