import request from "@/utils/request";

function getUrl(url) {
    return "/shop/" + url;
}

export default {
    captcha(data) {
        return request({
            url: getUrl("captcha/captcha.jpg"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            responseType:"blob",
            data,
        })
    },
}
