import request from "@/utils/request";

function getUrl(url) {
    return "/shop-test/" + url;
}

export default {
    listPage(data){
        return request({
            url: getUrl("customer/shoppingGold/record/listPage"),
            method: "post",
            data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
    },
    del(data){
        return request({
            url: getUrl("customer/shoppingGold/record/del"),
            method: "post",
            data,
        })
    },
    revoke(data){
        return request({
            url: getUrl("customer/shoppingGold/record/revoke"),
            method: "post",
            data,
        })
    },
}
