import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("afterSales/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("afterSales/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("afterSales/update"),
            method: "post",
            data,
        })
    },
    afterSalesProcess(data) {
        return request({
            url: getUrl("afterSales/afterSalesProcess"),
            method: "post",
            data,
        })
    },

    del(data) {
        return request({
            url: getUrl("afterSales/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("afterSales/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("afterSales/listPage"),
            method: "post",
            data,
        })
    },

}
