import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    getLogisticsInformation(data) {
        return request({
            url: getUrl("app/kuaidi100/getLogisticsInformation/" + data),
            method: "post",
            data,
        })
    },
    getLogisticsInformationResultList(data) {
        return request({
            url: getUrl("app/kuaidi100/getLogisticsInformationResultList/" + data),
            method: "post",
            data,
        })
    },
    getLogisticsInformationByOrderItem(data) {
        return request({
            url: getUrl("app/kuaidi100/getLogisticsInformationByOrderItem/" + data),
            method: "post",
            data,
        })
    },
    synchronizeLogisticsInformation(data) {
        return request({
            url: getUrl("app/kuaidi100/synchronizeLogisticsInformation/" + data),
            method: "post",
            data,
        })
    },

}
