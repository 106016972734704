<template>
    <div>
        <div class="title flex-between-center" v-if="titleShow">
            <div class="flex-center">
                <div class="cloume-line"></div>
                <span class="text">{{ title }}</span>
                <slot name="left"></slot>
            </div>
            <div class="right">
                <slot name="right"></slot>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
            },
            titleShow: {
                type: Boolean,
                default: true
            },
        },
    };
</script>
<style lang="scss" scoped>
  .title {
    margin: 0 20px;
    padding: 13px 0;
    border-bottom: 1px solid #e8ecf0;
  }

  .cloume-line {
    height: 16px;
    width: 4px;
    margin-right: 8px;
    background: #00a2ff;
    border-radius: 2px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .right > * {
    margin-left: 10px;
  }
</style>
