import request from "@/utils/request";
function getUrl(url) {
     return "/shop-test/" + url;
}
const uploadPic = getUrl("oss/uploadPic")
const uploadFile = getUrl("oss/uploadFile")

export {uploadPic,uploadFile}

export default {
    upload(data){
        return request({
            url: getUrl("oss/uploadPic"),
            method: "post",
            data,
        })
    },
    uploadFile(data){
        return request({
            url: getUrl("oss/uploadFile"),
            method: "post",
            data,
            // headers: {
            //     "Content-Type": "application/x-www-form-urlencoded",
            // },
            headers: {
                "Content-Type": "multipart/form-data", // 修改 Content-Type
            },
        })
    },
    uploadFiles(data){
        return request({
            url: getUrl("oss/uploadFiles"),
            method: "post",
            data,
            headers: {
                "Content-Type": "multipart/form-data", // 修改 Content-Type
            },
        })
    },
    uploadVideo(data){
        return request({
            url: getUrl("oss/uploadFile"),
            method: "post",
            data,
        })
    },
}
