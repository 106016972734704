import request from "@/utils/request";

function getUrl(url) {
    return "/shop-test/" + url;
}

export default {
    listPage(data) {
        return request({
            url: getUrl("goodsBrowseRecord/listPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("goodsBrowseRecord/del"),
            method: "post",
            data,
        })
    },
}
