import {getToken, removeToken, setToken} from '@/utils/auth'
import {getMenuTree, getUserInfo, login} from '@/api/auth'
import {getSupplierID} from "@/api/supplier";
import store from "@/store";
import router from "@/router";


function formatDateTime(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const ampm = hour < 12 ? 'AM' : 'PM';

    return `${year}-${month}-${day} 星期${'日一二三四五六'[date.getDay()]} ${ampm} ${hour}:${minute}:${second}`;
}


const state = {
    token: getToken(),
    roles: [],
    userInfo: {},
    loginInfo: {
        dateStr:'',
    },
    supplierId: null,
    menus: [
        {
            path: "/",
            redirect: "/dashboard",
            children: [
                {
                    name: '首页',
                    icon: "el-icon-ed-caidan",
                }
            ]
        }
    ]
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USERNAME: (state, userName) => {
        state.userName = userName
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_MENU: (state, menus) => {
        state.menus = menus
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_SUPPLIER_ID(state, status) {
        state.userInfo.supplierId = status
    },
    SET_DATESTR(state, dataStr) {
        state.loginInfo.dataStr = dataStr
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(res => {
                commit('SET_TOKEN', getToken())
                commit('SET_DATESTR', formatDateTime(new Date()))
                window.localStorage.setItem('dataStr',formatDateTime(new Date()))
                setToken(res.result.tokenValue)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },


    // get user info
    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            //获取用户个人信息数据
            getUserInfo().then(res => {
                commit('SET_USER_INFO', res.result)
                commit('SET_ROLES', res.result.roleIds)
                    getSupplierID(store.getters.user.accountType).then(data => {
                        commit('SET_SUPPLIER_ID', data.data[0].id)
                        resolve(res)
                    }).catch(error => {
                        if (error.message == '该账号角色为供应商，但未绑定有效的供应商') {
                            console.log("错误", error)
                            router.push(`index`)
                        }
                    })
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            //获取用户个人信息数据
            removeToken()
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_USER_INFO', {})
            commit('SET_MENU', {})
            resolve()
        })
    },

    getMenuTree({commit}, menu) {
        return new Promise((resolve, reject) => {
            getMenuTree(menu).then(res => {
                commit('SET_MENU', res.result)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    // dynamically modify permissions
    async changeRoles({commit, dispatch}, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, {root: true})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
