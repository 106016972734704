import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    getInfo(data) {
        return request({
            url: getUrl("insideBalance/getInfo/"+data),
            method: "get",
        })
    },
    add(data) {
        return request({
            url: getUrl("insideBalance/add"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("insideBalance/del"),
            method: "post",
            data,
        })
    },

}
