import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import qs from 'qs';
import "./permission";
import store from "./store";
Vue.prototype.$store = store

import components from "@/components";
components(Vue)

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
import plugins from './plugins' // plugins
Vue.use(plugins)
import api from "@/api/index"; // 导入api接口

import Element from 'element-ui';
import "@/styles/element-variables.scss";
import PubSub from "pubsub-js";
Vue.use(Element, { size: 'small', zIndex: 3000 });

import "@/styles/index.scss";

//自定义拦截器
import * as filters from './utils/filter.js'
import '@/assets/scss/index.scss'



import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 将 qs 和 JSON 对象挂载到 Vue 的原型上
Vue.prototype.$qs = qs;
Vue.config.productionTip = false
Vue.prototype.PubSub = PubSub   //组件发布订阅消息
Vue.prototype.$api = api;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
