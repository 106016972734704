<template>
    <el-container>
        <el-aside width="170px">
            <i class="el-icon-s-fold" @click="isC"></i>
            <sidebar></sidebar>
        </el-aside>
        <el-container>
            <el-header>
                <ecare-header></ecare-header>
            </el-header>
            <el-main>
                <div class="app-container">
                    <router-view/>
                </div>
            </el-main>
            <el-footer height="40px">
                <div style="text-align: center;color: #c8c9cc">
                    © 2023 疆小福 v2312282137
                </div>
            </el-footer>
        </el-container>
    </el-container>
</template>

<script>
    import Sidebar from "@/ecareLay/compoments/Sidebar";
    import AppMain from "@/ecareLay/compoments/AppMain";
    import EcareHeader from "@/ecareLay/compoments/Header";

    export default {
        name: "index",
        components: {AppMain, Sidebar, EcareHeader},
        data () {
            return {
                isCollapse: true
            }
        },
        methods: {

            isC(){
                this.isCollapse = !this.isCollapse
            }
        }
    }
</script>

<style scoped>
    .el-menu-vertical:not(.el-menu--collapse) {
        width: 170px;
    }
</style>
