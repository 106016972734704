import { render, staticRenderFns } from "./paginationSelect.vue?vue&type=template&id=5bfbd7e7&scoped=true&"
import script from "./paginationSelect.vue?vue&type=script&lang=js&"
export * from "./paginationSelect.vue?vue&type=script&lang=js&"
import style0 from "./paginationSelect.vue?vue&type=style&index=0&id=5bfbd7e7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfbd7e7",
  null
  
)

export default component.exports