import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}


export function getSupplierID(data) {
    return request({
        url: getUrl("supplier/getSupplierUserByUUID/"+data),
        method: 'post',
        data:data
    })
}

export default {
    list(data) {
        return request({
            url: getUrl("supplier/list"),
            method: "post",
            data,
        })
    },
    getSupplierUserByUUID(data) {
        return request({
            url: getUrl("supplier/getSupplierUserByUUID/"+data),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("supplier/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("supplier/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("supplier/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("supplier/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("supplier/listPage"),
            method: "post",
            data,
        })
    },
    getLeftChartData(data) {
        return request({
            url: getUrl("supplier/getLeftChartData"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getRightChartData(data) {
        return request({
            url: getUrl("supplier/getRightChartData"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },

}
