import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    listPage(data) {
        return request({
            url: getUrl("skuInfo/listPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    listAll(data) {
        return request({
            url: getUrl("skuInfo/listAll/" + data),
            method: "post",
            data,
        })
    },
    getPrice(data) {
        return request({
            url: getUrl("skuInfo/" + data + "/price"),
            method: "get",
            data,
        })
    },
    info(data) {
        return request({
            url: getUrl("skuInfo/info/" + data),
            method: "get",
            data,
        })
    },
    save(data) {
        return request({
            url: getUrl("skuInfo/save"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("skuInfo/update"),
            method: "post",
            data,
        })
    },
    deleteBatch(data) {
        return request({
            url: getUrl("skuInfo/delete"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("skuInfo/del"),
            method: "post",
            data,
        })
    },
    getSkuInfo(data) {
        return request({
            url: getUrl("skuInfo/skuItem/" + data),
            method: "get",
        })
    }

}
