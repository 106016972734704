import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("share/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("share/add"),
            method: "post",
            data,
        })
    },
    bindLogistics(data) {
        return request({
            url: getUrl("share/bindLogistics"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("share/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("share/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("share/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("share/listPage"),
            method: "post",
            data,
        })
    },
    updateStatus(data) {
        return request({
            url: getUrl("share/updateStatus"),
            method: "post",
            data,
        })
    },

}
