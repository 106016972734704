import request from "@/utils/request";
function getUrl(url) {
    return "/user-auth/" + url;
}

export default {
    getMenuTree(data){
        return request({
            url: getUrl("menu/getMenuTree"),
            method: "post",
            data,
        })
    },
    saveMenu(data){
        return request({
            url: getUrl("menu/saveMenu"),
            method: "post",
            data,
        })
    },
    deleteMenu(data){
        return request({
            url: getUrl("menu/deleteMenu"),
            method: "post",
            data,
        })
    },
    getRolePage(data){
        return request({
            url: getUrl("menu/getRolePage"),
            method: "post",
            data,
        })
    },
    updateRoleMenu(data){
        return request({
            url: getUrl("menu/updateRoleMenu"),
            method: "post",
            data,
        })
    },
    getRoleList(data){
        return request({
            url: getUrl("menu/getRoleList"),
            method: "post",
            data,
        })
    },
    getRole(data){
        return request({
            url: getUrl("menu/getRole"),
            method: "post",
            data,
        })
    },
    deleteRole(data){
        return request({
            url: getUrl("menu/deleteRole"),
            method: "post",
            data,
        })
    },
    saveRole(data){
        return request({
            url: getUrl("menu/saveRole"),
            method: "post",
            data,
        })
    }
}
