import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    getWeights() {
        return request({
            url: getUrl("pushSystem/getWeights/"),
            method: "get",
        })
    },
    saveWeights(data) {
        return request({
            url: getUrl("pushSystem/saveWeights"),
            method: "post",
            data
        })
    },

}
