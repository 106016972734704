import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    sync() {
        return request({
            url: getUrl("machine/sync"),
            method: "post"
        })
    },
    delWay(data) {
        return request({
            url: getUrl("machine/way/delWay"),
            method: "post",
            data
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("machine/getInfo/"+data),
            method: "get",
        })
    },
    getPage(data) {
        return request({
            url: getUrl("machine/getPage"),
            method: "post",
            data
        })
    },
    upd(data) {
        return request({
            url: getUrl("machine/upd"),
            method: "post",
            data
        })
    },
    getWayList(data) {
        return request({
            url: getUrl("machine/way/getList"),
            method: "post",
            data
        })
    },
    updWay(data) {
        return request({
            url: getUrl("machine/way/updById"),
            method: "post",
            data
        })
    },
    bindBeverage(data) {
        return request({
            url: getUrl("machine/way/bindBeverage"),
            method: "post",
            data
        })
    },
    addWay(data) {
        return request({
            url: getUrl("machine/way/addWay"),
            method: "post",
            data
        })
    },
    allReplenishment(data) {
        return request({
            url: getUrl("machine/way/allReplenishment"),
            method: "post",
            data
        })
    },
    openDoorReplenishment(data) {
        return request({
            url: getUrl("machine/openDoorReplenishment?imei="+data),
            method: "post"
        })
    },
    replenishment(data) {
        return request({
            url: getUrl("machine/way/replenishment"),
            method: "post",
            data
        })
    },
    allWay(data) {
        return request({
            url: getUrl("machine/way/all"),
            method: "post",
            data,
        })
    },
    countWay(data) {
        return request({
            url: getUrl("machine/way/countWay/"+data),
            method: "get",
        })
    },
    all(data) {
        return request({
            url: getUrl("machine/all"),
            method: "post",
            data,
        })
    },
}
