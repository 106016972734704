import request from "@/utils/request";
function getUrl(url) {
    // return "/user-auth/" + url;
    //  return "/shop-test/" + url;
    return "/shop/" + url;
}

export default {
    login(data){
        return request({
            url: getUrl("authUser/login"),
            method: "post",
            data,
        })
    },
    getUserInfo(data){
        return request({
            url: getUrl("authUser/getUserInfo"),
            // url:  "/user-auth/" + "auth/serInfo",
            method: "post",
            data,
        })
    }
}

export function getUserInfo() {
    return request({
        url: getUrl("authUser/getUserInfo"),
        // url:  "/user-auth/" + "auth/getUserInfo",
        method: 'get'
    })
}

export function changePassword(data) {
    return request({
        url: getUrl("authUser/changePassword"),
        method: 'post',
        data
    })
}


export function login(data) {
    return request({
        url: getUrl("authUser/login"),
        method: 'post',
        data
    })
}
export function getMenuTree(data) {
    return request({
        url: getUrl("menu/getMenuTree"),
        method: 'post',
        data
    })
}
