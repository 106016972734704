const getters = {
    token: state => state.user.token,
    user: state => state.user.userInfo,
    loginInfo: state => state.user.loginInfo,
    supplierId: state => state.user.supplierId,
    roles: state => state.user.roles,
    menus: state => state.user.menus,
    options: state => state.loading.options,
}
export default getters
