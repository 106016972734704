import axios from 'axios'
import router from '../router'
// import store from '@/store'
import {getToken, removeToken, setToken} from '@/utils/auth'
import {getSupplierID} from  '@/api/supplier'
import store from '@/store'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: "https://gateway.jiangxiaofu.com", // url = base url + request url
    // baseURL: process.env .VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 1000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // 默认传递json请求
        if (config.headers && config.headers['Content-Type']){
        }else {
            config.headers['Content-Type'] = 'application/json';
        }
        if (getToken()) {
            config.headers['UchungToken'] = getToken();
            // 注意：config.method 的判断值必须是小写的 post 和 get
            let user = store.getters.user;
            console.log("用户信息", user);
            // 如果参数中包含数组，将数组参数单独提取出来
            let arrayParam = null;
            if (Array.isArray(config.data)) {
                arrayParam = config.data;
                config.data = {}; // 清空原始数据
            }

            if (config.method === 'post') {

                if (config.data && typeof config.data === 'object' &&  (config.data.accountType == null|| config.data.accountType == undefined) && !(config.data instanceof FormData)) {
                    console.log("config.data",config.data);
                    config.data = Object.assign({'accountType': user.accountType}, config.data);
                }

                // if (user.accountType != 999 && user.accountType != 777 && config.data && typeof config.data === 'object' && (config.data.supplierId == null || config.data.supplierId == undefined)) {
                //     console.log("config.data",config.data);
                //     config.data = Object.assign({'supplierId': user.supplierId||-1 }, config.data);
                // }

                if (user.accountType != 999 && user.accountType != 777 && config.data && typeof config.data === 'object' && (config.data.supplierId == null || config.data.supplierId == undefined)){
                    console.log("config.data.supplierId",config.data.supplierId)
                    // 检查 Content-Type 是否为 JSON
                    if (config.headers && config.headers['Content-Type'] === 'application/json') {
                        // 如果是 JSON，直接添加新的字段 'supplierId'
                        // config.data = Object.assign({'supplierId': user.supplierId||-1 }, config.data);
                        config.data = Object.assign({}, config.data, {
                            supplierId: user.supplierId !== null ? user.supplierId : -1
                        });
                    }else if (!(config.data instanceof FormData)) {   // 检查 config.data 是否为 FormData
                        // 创建一个新的 FormData 对象
                        var formData = new FormData();
                        // 添加现有的 config.data 字段
                        for (var key in config.data) {
                            if (config.data[key]!=null && config.data[key]!=undefined){
                                formData.append(key, config.data[key]);
                            }
                        }
                        // 检查 user.supplierId 是否存在
                        if (user.supplierId !== undefined && user.supplierId !== null) {
                            // 添加新的字段 'supplierId'
                            formData.append('supplierId', user.supplierId);
                        }
                        // 将新的 FormData 对象赋给 config.data
                        config.data = formData;
                    }else {
                        // 如果已经是 FormData，直接添加新的字段 'supplierId'
                        config.data.append('supplierId', user.supplierId !== undefined ? user.supplierId : -1);
                    }
                }


                if (user.accountType !== 999 && user.accountType !== 777 && user.accountType !== 888 && config.data && typeof config.data === 'object' && (config.data.supplierSonId === null || config.data.supplierSonId === undefined)){
                    console.log("config.headers['Content-Type']",config.headers['Content-Type'])
                    if (config.headers && config.headers['Content-Type'] === 'application/json') {
                        config.data = Object.assign({'supplierSonId': user.supplierSonId||-1 }, config.data);
                    }else if (!(config.data instanceof FormData)) {
                        var formData = new FormData();
                        for (var key in config.data) {
                            if (config.data[key]!=null && config.data[key]!=undefined){
                                formData.append(key, config.data[key]);
                            }
                        }
                        if (user.supplierSonId !== undefined && user.supplierSonId !== null) {
                            formData.append('supplierSonId', user.supplierSonId);
                        }
                        config.data = formData;
                    }else {
                        config.data.append('supplierSonId', user.supplierSonId !== undefined ? user.supplierSonId : -1);
                    }
                }



                // 将数组参数添加到请求中
                if (arrayParam !== null) {
                    config.data.arrayParam = arrayParam;
                }
            } else if (config.method === 'get') {
                config.params = Object.assign({ 'accountType': user.accountType }, config.params);
                // console.log("config.params", config);
                if (user.accountType != 999 && user.accountType != 777) {
                    config.params = Object.assign({'supplierId': user.supplierId||-1 }, config.params);
                }

                if (user.accountType != 999 && user.accountType != 777 && user.accountType != 888) {
                    config.params = Object.assign({'supplierSonId': user.supplierSonId||-1 }, config.params);
                }

                // 将数组参数添加到请求中
                if (arrayParam !== null) {
                    config.params.arrayParam = arrayParam;
                }
            }
            // 文件下载的 responseType 设置
            if (config.responseType === 'download') {
                config.responseType = 'blob';
            }
        }
        return config;
    },
    error => {
        // do something with request error
        console.log("错误",error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        // console.log("service.interceptors.response",response)
        if (response.headers['UchungToken'] != undefined) {
            setToken(response.headers['UchungToken'])
        }
        const res = response.data
        if (res.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            let fileName = response.headers["content-disposition"].substr(20)
            console.log(fileName)
            //浏览器兼容，Google和火狐支持a标签的download，IE不支持
            let blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            if (window.navigator && window.navigator.msSaveBlob) {
                //IE浏览器、微软浏览器
                /* 经过测试，微软浏览器Microsoft Edge下载文件时必须要重命名文件才可以打开，
                  IE可不重命名，以防万一，所以都写上比较好 */
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                //其他浏览器
                let link = document.createElement('a'); // 创建a标签
                link.style.display = 'none';
                let objectUrl = URL.createObjectURL(blob);
                link.href = objectUrl;
                link.download = decodeURIComponent(fileName)
                link.click();
                URL.revokeObjectURL(objectUrl);
            }
            return Promise.resolve()
        }
        if (response.config.url.includes("captcha.jpg")){
            return res
        } else if (res.code !== 200 && res.code !== 0) {
            if (res.code == 401) {
                removeToken();
                router.push(`/login?redirect=` + router.currentRoute.fullPath)
            } else {
                return Promise.reject(res)
            }
        } else {
            return res
        }
    },
    error => {
        console.log("error",error)
        console.log("error",error.response)
        if (error.response.status == 401) {
            return Promise.reject({code: 401, data: null, msg: "暂无权限"})
        } else {
            // Message({
            //     message: error.message,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return Promise.reject(error)
        }
    }
)

export default service
