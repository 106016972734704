import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("spuInfo/list"),
            method: "post",
            data,
        })
    },
    delBySpuId(data){
        return request({
            url: getUrl("spuInfo/delBySpuId/"+data),
            method: "post",
        })
    },
    productDetails(data) {
        return request({
            url: getUrl("spuInfo/productDetails/"+data),
            method: "get",
            data,
        })
    },

    info(data) {
        return request({
            url: getUrl("spuInfo/info/"+data),
            method: "get"
        })
    },
    listInfoDetailsBySpuId(data){
        return request({
            url: getUrl("spuInfo/listInfoDetailsBySpuId/"+data),
            method: "get",
        })
    },
    spuInfo(data) {
        return request({
            url: getUrl("spuInfo/spuInfo/"+data),
            method: "get",
            data,
        })
    },
    spuInfoSave(data) {
        return request({
            url: getUrl("spuInfo/save"),
            method: "post",
            data,
        })
    },
    spuUp(data) {
        return request({
            url: getUrl("spuInfo/"+data+"/up"),
            method: "post",
            data,
        })
    },
    listPage(data) {
        return request({
            // url: getUrl("spuInfo/listPage"),
            url: getUrl("spuInfo/spuInfoListPage"),
            method: "post",
            data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
    },
    updateStatus(data){
        return request({
            url: getUrl("spuInfo/updateStatus"),
            method: "post",
            data,
        })
    },
    publishProduct(data){
        return request({
            url: getUrl("spuInfo/publishProduct"),
            method: "post",
            data,
        })
    },
    modifyProduct(data){
        return request({
            url: getUrl("spuInfo/modifyProduct"),
            method: "post",
            data,
        })
    },
    batchModifyPrices(data){
        return request({
            url: getUrl("spuInfo/batchModifyPrices"),
            method: "post",
            data,
        })
    },


}
