import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("customerAddress/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("customerAddress/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("customerAddress/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("customerAddress/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("customerAddress/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("customerAddress/listPage"),
            method: "post",
            data,
        })
    },

}
