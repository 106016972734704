import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("sys/config/list"),
            method: "post",
            data,
        })
    },
    save(data) {
        return request({
            url: getUrl("sys/config/save"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("sys/config/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("sys/config/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("sys/config/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("sys/config/listPage"),
            method: "post",
            data,
        })
    },

}
