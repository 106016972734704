import Vue from "vue";
import VueRouter from "vue-router";
import ecareLay from "@/ecareLay/index.vue";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;

// push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export const commonRouters = [
    {
        path: "/login",
        hidden: true,
        meta: {title: "", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.vue"),
        // component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.bak.vue"),
    },
    {
        path: "/",
        component: ecareLay,
        redirect: "/index",
        children: [
            {
                path: "/indexPlus",
                meta: {title: "首页", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/indexPlus.vue"),
            },
            {
                path: "/index",
                meta: {title: "首页", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/index.vue"),
            },
            {
                path: "/dataBoard",
                meta: {title: "首页", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/new"),
            }
        ],
    },
    {
        path: "/monitor",
        component: ecareLay,
        redirect: "/monitor",
        children: [
            {
                path: "/monitor/log",
                meta: {title: "系统日志", activeMenu:'/monitor/log', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/log/list.vue"),
            },
            {
                path: "/monitor/log/info",
                meta: {title: "系统日志详情", activeMenu:'/monitor/log', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/log/info.vue"),
            },
        ],
    },
    {
        path: "/notice",
        component: ecareLay,
        redirect: "/notice",
        children: [
            {
                path: "/notice/list",
                meta: {title: "通知管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/notice/list.vue"),
            },
            {
                path: "/notice/type",
                meta: {title: "通知类型", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/notice/type/list.vue"),
            },
            {
                path: "/notice/record",
                meta: {title: "通知记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/notice/record/list.vue"),
            },
        ],
    },
    {
        path: "/system",
        component: ecareLay,
        redirect: "/system",
        children: [
            {
                path: "/system/user",
                meta: {title: "用户管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/user/list.vue"),
            },
            {
                path: "/system/user/profile",
                meta: {title: "个人中心", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/user/profile/index.vue"),
            },
            {
                path: "/system/user/userInfo",
                meta: {title: "会员详情", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/user/userInfo.vue"),
            },
            {
                path: "/system/role",
                meta: {title: "角色管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/role/list.vue"),
            },
            {
                path: "/system/menu",
                meta: {title: "菜单管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/menu/list.vue"),
            },
            {
                path: "/system/config",
                meta: {title: "系统设置", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/config/list.vue"),
            },
            {
                path: "/system/log",
                meta: {title: "系统日志", activeMenu:'/monitor/log', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/log/list.vue"),
            },
            {
                path: "/system/log/info",
                meta: {title: "系统日志详情", activeMenu:'/monitor/log', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/log/info.vue"),
            },
            {
                path: "/system/configuration",
                meta: {title: "系统设置", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/configuration/index.vue"),
            },
        ],
    },
    {
        path: "/appManager",
        component: ecareLay,
        redirect: "/appManager",
        children: [
            {
                path: "/appManager/rotationChart",
                meta: {title: "轮播图管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/rotationChart/list.vue"),
            },
            {
                path: "/appManager/share",
                meta: {title: "首页分享管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/share/list.vue"),
            },
            {
                path: "/appManager/homeIcon",
                meta: {title: "首页小图标管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/homeIcon/list.vue"),
            },
            {
                path: "/appManager/disseminate",
                meta: {title: "首页广告图管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/disseminate/list.vue"),
            },
            {
                path: "/appManager/protocol",
                meta: {title: "协议管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/protocol/list.vue"),
            },
            {
                path: "/appManager/appKeyWordSearchRecord",
                meta: {title: "关键词搜索统计", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/appKeyWordSearchRecord/list.vue"),
            },
            {
                path: "/appManager/searchRecord",
                meta: {title: "关键词搜索流水", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/searchRecord/list.vue"),
            },
            {
                path: "/appManager/market",
                meta: {title: "营销管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/market/list.vue"),
            },
            {
                path: "/appManager/sysRelease",
                meta: {title: "公告管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/sysRelease/list.vue"),
            },
            {
                path: "/appManager/notice",
                meta: {title: "全站通知管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/notice/list.vue"),
            },{
                path: "/appManager/appGreyList",
                meta: {title: "灰名单管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/appGreyList/list.vue"),
            },{
                path: "/appManager/blacklist",
                meta: {title: "黑名单管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/appManager/blacklist/list.vue"),
            },
        ],
    }
    , {
        path: "/product",
        component: ecareLay,
        redirect: "/product",
        children: [
            {
                path: "/product/brand",
                meta: {title: "品牌管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/brand/brand.vue"),
            },
            {
                path: "/product/category",
                meta: {title: "分类维护", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/category/category.vue"),
            },
            {
                path: "/product/attr/attrgroup",
                meta: {title: "属性分组", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/attr/attrgroup.vue"),
            },
            {
                path: "/product/attr/baseattr",
                meta: {title: "商品规格", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/attr/baseattr.vue"),
            },
            {
                path: "/product/attr/attrupdate",
                meta: {title: "规格修改", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/attr/attrupdate.vue"),
            },
            {
                path: "/product/attr/saleattr",
                meta: {title: "销售属性", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/attr/saleattr.vue"),
            },
            {
                path: "/product/spu",
                meta: {title: "商品列表", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/spu/spu.vue"),
            },
            {
                path: "/product/spu/spuAddOrUpdate",
                meta: {title: "商品发布", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/spu/spuAddOrUpdate/index.vue"),
            },
            {
                path: "/product/spu/spuDetails",
                meta: {title: "商品详情", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/spu/spuDetails.vue"),
            },
            {
                path: "/product/spuadd",
                meta: {title: "商品发布", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/spu/spuadd.vue"),
            },
            {
                path: "/product/simpleSpuAdd",
                meta: {title: "快捷发布", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/spu/simpleSpuAdd.vue"),
            },
            {
                path: "/product/sku",
                meta: {title: "商品列表", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/sku/manager.vue"),
            },
            {
                path: "/product/serviceOptions",
                meta: {title: "服务选项管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/sku/serviceOptions/list.vue"),
            },
            {
                path: "/product/tag/list",
                meta: {title: "商品标签管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/tag/list.vue"),
            },
            {
                path: "/product/browsingHistory",
                meta: {title: "浏览记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/spu/goods/browsingHistory/list.vue"),
            },

        ],
    },
    {
        path: "/customer",
        component: ecareLay,
        redirect: "/customer",
        children: [
            {
                path: "/customer/",
                meta: {title: "会员列表", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/list.vue"),
            },
            {
                path: "/customer/info",
                meta: {title: "会员详情", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/info.vue"),
            },
            {
                path: "/customer/address",
                meta: {title: "收货地址", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/address/list.vue"),
            },
            {
                path: "/customer/wallet",
                meta: {title: "餐卡记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/wallet/list.vue"),
            },
            {
                path: "/customer/shoppingGold",
                meta: {title: "购物金记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/shoppingGold/list.vue"),
            },
            {
                path: "/customer/shoppingGold/record",
                meta: {title: "购物金充值记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/shoppingGold/record/list.vue"),
            },
            {
                path: "/customer/integral",
                meta: {title: "积分记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/integral/list.vue"),
            },
            {
                path: "/customer/xFlow",
                meta: {title: "X账户记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/xwallet/list.vue"),
            },
            {
                path: "/customer/cart",
                meta: {title: "会员购物车", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/cart/list.vue"),
            },
            {
                path: "/customer/browse/list",
                meta: {title: "浏览足迹", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/customer/browse/list.vue"),
            },
        ],
    },
    // {
    //     path: "/cargoAircraft",
    //     component: ecareLay,
    //     redirect: "/cargoAircraft",
    //     children: [
    //         {
    //             path: "/cargoAircraft/beverageList",
    //             meta: {title: "饮料管理", icon: "el-icon-ed-shouye", affix: true},
    //             component: () => import(/* webpackChunkName: "login" */ "../pages/cargoAircraft/beverageList.vue"),
    //         },
    //         {
    //             path: "/cargoAircraft/order",
    //             meta: {title: "饮料订单", icon: "el-icon-ed-shouye", affix: true},
    //             component: () => import(/* webpackChunkName: "login" */ "../pages/cargoAircraft/order/info.vue"),
    //         },
    //     ],
    // },
    {
        path: "/supplier",
        component: ecareLay,
        redirect: "/supplier",
        children: [
            {
                path: "/supplier",
                meta: {title: "供应商管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/supplier/list.vue"),
            },
            {
                path: "/supplier/info",
                meta: {title: "供应商详情", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/supplier/info.vue"),
            },
            {
                path: "/supplier/son",
                meta: {title: "子应商管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/supplier/son/list.vue"),
            },
            {
                path: "/supplier/son/info",
                meta: {title: "子应商详情", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/supplier/son/info.vue"),
            },
            {
                path: "/supplier/user",
                meta: {title: "供应商用户管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/supplier/user/list.vue"),
            },
        ]
    },
    {
        path: "/finance",
        component: ecareLay,
        redirect: "/finance",
        children: [
            {
                path: "/finance/capitalFlow",
                meta: {title: "供应商结算", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/finance/capitalFlow/list.vue"),
            },
            {
                path: "/finance/inside",
                meta: {title: "内部结算", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/finance/inside/list.vue"),
            },
            {
                path: "/finance/emp",
                meta: {title: "企业结算", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/finance/emp/list.vue"),
            }, {
                path: "/finance/operationRecord",
                meta: {title: "运营记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/finance/operationRecord/list.vue"),
            },
        ]
    },
    {
        path: "/order",
        component: ecareLay,
        redirect: "/order",
        children: [
            {
                path: "/order/order",
                meta: {title: "订单管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/order/list.vue"),
            },
            {
                path: "/order/orderItem",
                meta: {title: "订单项管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/order/item/list.vue"),
            },
            {
                path: "/order/afterSales",
                meta: {title: "售后管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/order/afterSales/list.vue"),
            },
            {
                path: "/order/logistics",
                meta: {title: "物流公司", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/order/logistics/list.vue"),
            },
        ]
    },
    {
        path: "/machine",
        component: ecareLay,
        redirect: "/machine",
        children: [
            {
                path: "/machine/list",
                meta: {title: "货机列表", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/list.vue"),
            },
            {
                path: "/machine/way/edit",
                meta: {title: "货道管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/way/edit.vue"),
            },
            {
                path: "/machine/model/list",
                meta: {title: "模型管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/model/list.vue"),
            },
            {
                path: "/machine/beverage/list",
                meta: {title: "饮料管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/beverage/beverageList.vue"),
            },
            {
                path: "/machine/beverage/orderList",
                meta: {title: "饮料订单", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/beverage/orderList.vue"),
            },
            {
                path: "/machine/order/list",
                meta: {title: "饮料订单", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/order/list.vue"),
            },
            {
                path: "/machine/cpfr/list",
                meta: {title: "补货记录", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/cpfr/list.vue"),
            },
            {
                path: "/machine/cpfr/imputation/list",
                meta: {title: "补货归集", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/cpfr/imputation/list.vue"),
            },
            {
                path: "/machine/cpfr/imputation/info",
                meta: {title: "补货详情", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/cpfr/imputation/info.vue"),
            },
            {
                path: "/machine/rotationChart/list",
                meta: {title: "货机轮播图管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/rotationChart/list.vue"),
            },
            {
                path: "/machine/msg/list",
                meta: {title: "消息通知管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/msg/list.vue"),
            },
            {
                path: "/machine/business/list",
                meta: {title: "商家端管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/business/list.vue"),
            },
            {
                path: "/machine/category",
                meta: {title: "饮料分类管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/category/category.vue"),
            },
            {
                path: "/machine/advertising/list",
                meta: {title: "广告图管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/advertising/list.vue"),
            },
            {
                path: "/machine/advertising/strategy/list",
                meta: {title: "广告图策略管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/advertising/strategy/list.vue"),
            },
            {
                path: "/machine/advertising/relation/list",
                meta: {title: "广告图策略关联", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/machine/advertising/relation/list.vue"),
            },
        ]
    },
    {
        path: "/pushSystem",
        component: ecareLay,
        redirect: "/pushSystem",
        children: [
            {
                path: "/pushSystem/weight",
                meta: {title: "权重管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/pushSystem/weight/index.vue"),
            },
        ]
    },
    {
        path: "/tests",
        component: ecareLay,
        redirect: "/tests",
        children: [
            {
                path: "/tests/save",
                meta: {title: "商品管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/test/save.vue"),
            },
            {
                path: "/tests/saves",
                meta: {title: "商品管理", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/test/saves.vue"),
            },
        ]
    },


]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes: commonRouters,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
