import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("disseminate/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("disseminate/add"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("disseminate/update"),
            method: "post",
            data,
        })
    },
    updateStatus(data) {
        return request({
            url: getUrl("disseminate/updateStatus"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("disseminate/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("disseminate/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("disseminate/listPage"),
            method: "post",
            data,
        })
    },


}
