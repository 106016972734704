import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    listPage(data) {
        return request({
            url: getUrl("paymentRecord/capitalFlow"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data
        })
    },
    internalEmployeeShar(data) {
        return request({
            url: getUrl("paymentRecord/internalEmployeeShar"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data
        })
    },
    empFlow(data) {
        return request({
            url: getUrl("paymentRecord/empFlow"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("paymentRecord/getInfo/"+data),
            method: "get",
        })
    },
    add(data) {
        return request({
            url: getUrl("paymentRecord/add"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("paymentRecord/del"),
            method: "post",
            data,
        })
    },
    empFlowExcelExport(data) {
        return request({
            url: getUrl("paymentRecord/empFlowExcelExport"),
            method: "post",
            responseType: "blob",  // 设置 responseType 为 blob
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },

}
