import request from "@/utils/request";

function getUrl(url) {
    return "/shop-test/" + url;
}

export default {
    updateModel(data) {
        return request({
            url: getUrl("app/aiFreighter/updateModel?imei="+data),
            method: "post"
        })
    },
}
