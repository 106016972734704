<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
body{
  padding: 0;
  margin: 0;
  overflow: auto !important;
}
.w-e-toolbar .w-e-panel-container {
    position: absolute;
    top: 32px;
    left: 50%;
    border: 1px solid #ccc;
    border-top: 0;
    box-shadow: 1px 1px 2px #ccc;
    color: #333;
    z-index: 999999999999999;
    background-color: #fff;
    /* 为 emotion panel 定制的样式 */
    /* 上传图片的 panel 定制样式 */
}

.w-e-toolbar .w-e-panel-container .w-e-panel-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    margin: 2px 5px 0 0;
    cursor: pointer;
    color: #999;
    background: #fff;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-close:hover {
    color: #333;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-title {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    margin: 2px 10px 0 10px;
    border-bottom: 1px solid #f1f1f1;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-title .w-e-item {
    padding: 3px 5px;
    color: #999;
    cursor: pointer;
    margin: 0 3px;
    position: relative;
    top: 1px;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-title .w-e-active {
    color: #333;
    border-bottom: 1px solid #333;
    cursor: default;
    font-weight: 700;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content {
    padding: 10px 15px 10px 15px;
    font-size: 16px;
    /* 输入框的样式 */
    /* 按钮的样式 */
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content input:focus,
.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content textarea:focus,
.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content button:focus {
    outline: none;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content textarea {
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content textarea:focus {
    border-color: #1e88e5;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content input[type=text] {
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    height: 20px;
    color: #333;
    text-align: left;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content input[type=text].small {
    width: 30px;
    text-align: center;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content input[type=text].block {
    display: block;
    width: 100%;
    margin: 10px 0;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content input[type=text]:focus {
    border-bottom: 2px solid #1e88e5;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container button {
    font-size: 14px;
    color: #1e88e5;
    border: none;
    padding: 5px 10px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 3px;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container button.left {
    float: left;
    margin-right: 10px;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container button.right {
    float: right;
    margin-left: 10px;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container button.gray {
    color: #999;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container button.red {
    color: #c24f4a;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container button:hover {
    background-color: #f1f1f1;
}

.w-e-toolbar .w-e-panel-container .w-e-panel-tab-content .w-e-button-container:after {
    content: "";
    display: table;
    clear: both;
}

.w-e-toolbar .w-e-panel-container .w-e-emoticon-container .w-e-item {
    cursor: pointer;
    font-size: 18px;
    padding: 0 3px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

.w-e-toolbar .w-e-panel-container .w-e-up-img-container {
    text-align: center;
}

.w-e-toolbar .w-e-panel-container .w-e-up-img-container .w-e-up-btn {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    color: #999;
    cursor: pointer;
    font-size: 60px;
    line-height: 1;
}

.w-e-toolbar .w-e-panel-container .w-e-up-img-container .w-e-up-btn:hover {
    color: #333;
}
</style>
