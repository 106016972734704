import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("customer/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("customer/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("customer/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("customer/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("customer/getInfo/"+data),
            method: "get",
        })
    },
    getInfoByPhone(data) {
        return request({
            url: getUrl("customer/getInfoByPhone/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("customer/listPage"),
            method: "post",
            data,
        })
    },
    all() {
        return request({
            url: getUrl("customer/all"),
            method: "post"
        })
    },
    getWalletSum() {
        return request({
            url: getUrl("customer/getWalletSum"),
            method: "post"
        })
    },
    allCustomer(data) {
        return request({
            url: getUrl("customer/allCustomer"),
            method: "post",
            data
        })
    },
    syncX(data) {
        return request({
            url: getUrl("customer/syncX"),
            method: "post",
            data
        })
    },
    newTotalBalanceMembers(data) {
        return request({
            url: getUrl("customerRecord/newTotalBalanceMembers"),
            method: "post",
            data
        })
    },

}
