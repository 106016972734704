import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}
// 规格管理

export default {
    list(data) {
        return request({
            url: getUrl("spec/list"),
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    page(data) {
        return request({
            url: getUrl("spec/list"),
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    listSpecValue(data) {
        return request({
            url: getUrl("spec/listSpecValue/"+data),
            method: "get",
            data,
        })
    },
    listSpecMaxValueId(data) {
        return request({
            url: getUrl("spec/listSpecMaxValueId"),
            method: "get",
            data,
        })
    },
    save(data) {
        return request({
            url: getUrl("spec/save"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("spec/update"),
            method: "post",
            data,
        })
    },
}
