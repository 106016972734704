import request from "@/utils/request";
 
function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    catelogListTree(data) {
        return request({
            url: getUrl("category/list/tree"),
            method: "get",
            data,
        })
    },
    delete(data) {
        return request({
            url: getUrl("category/delete"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("category/update"),
            method: "post",
            data,
        })
    },
    save(data) {
        return request({
            url: getUrl("category/save"),
            method: "post",
            data,
        })
    },
    info(data) {
        return request({
            url: getUrl("category/info/"+data),
            method: "get",
            data,
        })
    },
    updateSort(data) {
        return request({
            url: getUrl("category/update/sort"),
            method: "post",
            data,
        })
    },

}
