<template>
  <Card :titleShow="titleShow" :title=" titleShow?title:''">
    <template #left>
      <slot name="title-left"></slot>
    </template>
    <template #right>
      <el-button v-if="selection" @click="toggleSelection()">取消选择</el-button>
      <slot name="title-right"></slot>
    </template>
    <el-table
        @sort-change="tableSort" :data="data || []" class="table" :header-cell-style="{'text-align':'center'}"
        :cell-style="cellStyle" v-loading="loading"
        ref="multipleTable"
        @selection-change="$emit('selection-change', $event)"
        :row-key="rowKey"
        :expand-row-keys="expandRowKeys"
    >
      <el-table-column v-if="selection"
                       type="selection"
                       :selectable="checkSelectable"
                       :reserve-selection="true"
                       width="55">
      </el-table-column>
      <el-table-column v-if="hasIndex" width="50">
        <template slot-scope="scope">
          {{ hasSum && scope.$index === data.length - 1 ? "" : scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
          v-for="(column, index) in columns"
          :key="index"
          v-if=" user.accountType==999 || column.isAdmin == undefined"
          :label="column.title"
          :prop="column.dataIndex"
          :current-row-key="rowKey"
          :width="column.width"
          :show-overflow-tooltip="column.overflow==undefined"
          :type="column.slot=='expand'?'expand':''"
          :align="column.align || 'center'"
          :fixed="column.dataIndex=='action'?'right':false"
          :sortable="column.sortable||false"

      >
        <template slot-scope="scope">
          <div v-if="column.slot">
            <slot :name="column.slot"
                  :scope="{[column.dataIndex]: scope.row[column.dataIndex],index: scope.$index,row: scope.row,}"/>
          </div>
          <div v-else-if="column.imageWidth && column.showLargeImages">
            <el-tooltip v-if="scope.row[column.dataIndex]" :effect="'light'" placement="top">
              <div>
                <el-image
                    :style="'width: '+column.imageWidth+'; height: '+column.imageHeight+'display: block;'"
                    :src="scope.row[column.dataIndex]"
                    :preview-src-list="[scope.row[column.dataIndex]]">
                </el-image>
              </div>
              <div slot="content"
                   :style="'text-align: center; line-height: 0;width:100%'">
                <el-image
                    :style="'width: '+column.bigImageWidth+'; display: block;'"
                    :src="scope.row[column.dataIndex]"/>
              </div>
            </el-tooltip>
            <div v-else>暂无图片</div>
          </div>
          <span v-else-if="column.infoEvent">
               <span @click="toEnvel(scope.row,column.param)"
                     style="color: #00a2ff;cursor: pointer">{{ scope.row[column.dataIndex] || '-' }}
               </span>
          </span>
          <span v-else-if="column.isAmount">
            {{
              scope.row[column.dataIndex] !== null &&
              scope.row[column.dataIndex] !== undefined
                  ? (scope.row[column.dataIndex] / 100).toFixed(2)
                  : ""
            }}
          </span>
          <span v-else-if="column.customRender">
            {{
              column.customRender(scope.row[column.dataIndex], scope.row, index)
            }}
          </span>
          <span v-else-if="column.imageWidth">
                    <el-image v-if="scope.row[column.dataIndex]"
                              :style="'width: '+column.imageWidth+'; height: '+column.imageHeight"
                              :src="scope.row[column.dataIndex]==''||scope.row[column.dataIndex]==null?imgError:scope.row[column.dataIndex]">
                    </el-image>
                    <div v-else>暂无图片</div>
                      </span>
          <span v-else-if="column.statusTag">
                        <el-tag :type="scope.row[column.dataIndex]?'':'danger'">{{
                            scope.row[column.dataIndex] ? column.statusTag[0] : column.statusTag[1]
                          }}</el-tag>
                     </span>
          <span v-else-if="column.statusValues">
                         <el-tag :type="scope.row[column.dataIndex]?'':'danger'">{{
                             column.statusValues[scope.row[column.dataIndex]] ? column.statusValues[scope.row[column.dataIndex]] : '-'
                           }}</el-tag>
                     </span>
          <span v-else-if="column.tagEnable">
                         <el-tag v-if="scope.row[column.dataIndex]" :type="column.tagEnable">{{
                             scope.row[column.dataIndex]
                           }}</el-tag>
                         <div v-else>
                             -
                         </div>
                     </span>
          <span v-else-if="column.statusStyleValues">

                        <el-tag
                            v-if="column.statusStyleValues[column.statusStyleValues.findIndex(item => item.value == scope.row[column.dataIndex])]
                            &&scope.row[column.dataIndex]&&column.statusStyleValues[column.statusStyleValues.findIndex(item => item.value == scope.row[column.dataIndex])].label"
                            :type="column.statusStyleValues[column.statusStyleValues.findIndex(item=>item.value==scope.row[column.dataIndex])].style">
                            {{
                            column.statusStyleValues[column.statusStyleValues.findIndex(item => item.value == scope.row[column.dataIndex])].label
                          }}
                         </el-tag>

                        <el-tag v-else-if="scope.row[column.dataIndex]==0 ||scope.row[column.dataIndex]=='0'"
                                :type="column.statusStyleValues[column.statusStyleValues.findIndex(item=>item.value==scope.row[column.dataIndex])].style">
                           {{
                            column.statusStyleValues[column.statusStyleValues.findIndex(item => item.value == scope.row[column.dataIndex])].label
                          }}
                         </el-tag>
                        <div v-else-if="scope.row[column.dataIndex]">
                            {{ scope.row[column.dataIndex] }}
                        </div>
                        <span v-else>
                            {{ '-' }}
                          </span>
                     </span>
          <span v-else-if="column.options">
                    {{ getOptionsValue(scope.row[column.dataIndex], column.options) }}
                  </span>
          <span v-else-if="column.birthDate">
                        {{ scope.row[column.dataIndex] | birthDateFormat }}
                      </span>
          <span v-else-if="column.birthDateTime">
                        {{ scope.row[column.dataIndex] | birthDateTimeFormat }}
                      </span>
          <span v-else-if="column.date">
                        {{ scope.row[column.dataIndex] | dateFormat }}
                      </span>
          <span v-else-if="column.ymdhm">
                        {{ scope.row[column.dataIndex] | dateFormat2 }}
                      </span>
          <span v-else-if="column.payStatus">
                    {{ scope.row[column.dataIndex] | orderTradeState }}
                  </span>
          <span v-else-if="column.isTo">
                    <span @click="to(scope.row[column.toIndex], column.url)"
                          style="color: #00a2ff;cursor: pointer">{{ scope.row[column.dataIndex]  || column.dataType=='number'? scope.row[column.dataIndex]: '-' }}</span>
                     </span>
          <span v-else-if="column.dataType=='number'">
                            {{ scope.row[column.dataIndex] }}
                  </span>
          <span v-else-if="column.maskedName">
                            {{ maskedName(scope.row[column.dataIndex]) }}
                  </span>
          <span v-else-if="column.maskedPhone">
                            {{ maskedPhoneNumber(scope.row[column.dataIndex]) }}
                  </span>
          <span v-else-if="column.switchDisplay">
                       <el-switch
                           v-model="scope.row[column.dataIndex]"
                           active-color="#13ce66"
                           inactive-color="#ff4949"
                           :active-value="true"
                           :inactive-value="false"
                           @change="updateStatus(scope.row,column.dataIndex)"
                       ></el-switch>
          </span>
          <span v-else-if="column.isInfo">
            <span style="color: #00a2ff;cursor: pointer"
                  @click="clicks(scope.row)">{{ scope.row[column.dataIndex] || '-' }}</span>
                  </span>
          <span v-else-if="column.addIndex">
                    {{ scope.row[column.dataIndex] || '-' }}/{{ scope.row[column.addIndex] }}
                  </span>

          <span v-else>
               <span v-if="column.dataType=='number'">
                    {{ scope.row[column.dataIndex] }}
               </span>
              <span v-else>
                {{ scope.row[column.dataIndex] || '-' }}
               </span>
          </span>
        </template>
      </el-table-column>
      <template #empty>
        <div class="empty">
          <img src="../assets/img/empty.png"/>
          <div>暂无数据</div>
        </div>
      </template>
    </el-table>
    <el-pagination
        v-if="data && data.length !== 0 && usePagination"
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageNum"
        :page-sizes="pagination.pageSizeOptions"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
    >
    </el-pagination>
  </Card>
</template>

<script>
import {getUUID} from '@/utils'
import Card from "@/components/Card";
import {mapGetters} from "vuex";
import {dateFormat2} from "@/utils/filter";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  name: "BaseTable",
  components: {Card},
  props: {
    title: {
      type: String,
      default: "列表数据"
    },
    titleShow: {
      type: Boolean,
      default: true
    },
    pageType: {
      type: String,
      default: "old"
    },
    checkKey: {
      type: String,
      default: "isConfirm"
    },
    usePagination: {
      type: Boolean,
      default: true,
    },
    // 是否mount时获取数据
    immediate: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    getData: {
      type: Function,
    },
    rowKey: {
      default: "id",
    },
    expandRowKeys: {
      type: Array,
      default: [],
    },
    // 列表是否展示序号列
    hasIndex: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    hasExpand: {
      type: Boolean,
      default: false,
    },
    // 是否有总计
    hasSum: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    requestFun: {
      type: Function,
    },
    searchForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      imgError: require('@/assets/img/imgError.png'),
      pagination: {
        size: "small",
        total: 0,
        pageSize: 10,
        pageNum: 1,
        pageSizeOptions: ["10", "30", "45", "60", "75", "90", "500"],
      },
      total: 0,
      data: [],
      loading: false,
      // 多选选择的行
      selected: [],
      dragSourceIndex: null,
      tableSortInfo: {
        sortType: '',
        sortField: '',
      }
    };
  },
  mounted() {
    if (this.immediate) {
      this.getTableData();
    }
    this.$nextTick(function () {
      this.$on('updateList', function (data) {
        this.data = data
      })
    })
  },
  methods: {
    checkSelectable(row) {
      return !row[this.checkKey]
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    getRowKey(row) {
      return row.id + "";
    },
    uuid() {
      return getUUID();
    },
    maskedPhoneNumber(phone) {
      if (phone.length === 11) {
        const prefix = phone.substring(0, 3);
        const suffix = phone.substring(7);
        return prefix + '****' + suffix;
      } else {
        return this.phoneNumber; // 手机号不是11位不进行处理
      }
    },
    clicks(row) {
      this.$emit('clicks', row)
    },
    maskedName(name) {
      if (name.length > 1) {
        const firstChar = name[0];
        return firstChar + '**';
      } else {
        return name; // 姓名太短不进行处理
      }
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      if (column.property == 'action' || column.property == 'skuName') {
        // 检查是否是最后一行
        return {textAlign: 'left'}; // 设置文本居左
      }
      // return {textAlign: 'center'}; // 其他单元格保持默认样式
      return {}; // 其他单元格保持默认样式
    },
    dateFormat2,
    updateStatus(data, clo) {
      //发送请求修改状态
      console.log("最新状态信息", data, clo);
      this.$emit('updateStatus', data, clo)
    },
    tableSort(column) {
      if (column.order == 'descending') {
        this.tableSortInfo.sortType = 'desc'
      } else if (column.order == "ascending") {
        this.tableSortInfo.sortType = 'asc'
      }
      this.tableSortInfo.sortField = column.prop
      console.log("column", column.prop)
      this.$emit('tableSortCallback', this.tableSortInfo)
    },
    enlargeImage(imgUrl) {
      if (imgUrl) {
        this.enlargedImageUrl = imgUrl;
        this.enlargeImageFlag = true;
      }
    },
    to(ucId, url) {
      if (ucId && url) {
        window.open(url + ucId, "_blank");
      } else {
        this.$message('参数为空');
      }
    },
    toEnvel(row,param) {
       this.$emit('infoDetail', row,param);
    },
    async defaultGetData(
        pages = {
          pageNum: 1,
          pageSize: 10,
        }
    ) {
      const res = await this.requestFun({
        ...pages,
        ...this.searchForm,
      });
      if (this.pageType == 'new') {
        return {
          data: res.data.list || res.data,
          total: res.page.total,
        };
      } else {
        return {
          // data:  res.data.list,
          // total:  res.page.total,
          data: res.page ? res.data : res.data ? res.data.list : res.result.list,
          total: res.page ? res.page.total : res.data ? res.data.total : res.result.total,
        };
      }
    },
    async getTableData(pageNum = this.pagination.pageNum) {
      this.pageNum = pageNum;
      this.selected = [];
      this.pagination.pageNum = pageNum;
      this.loading = true;
      const getDataFun = this.getData || this.defaultGetData;
      getDataFun({
        pageNum,
        pageSize: this.pagination.pageSize - this.hasSum,
      }).then(({data, total, callbackFn}) => {
        this.data = data;
        this.total = total - 0;
        this.pagination.total = total;
        this.$emit("updateTableData", data);
        callbackFn && callbackFn();
      }).finally(() => {
        this.loading = false;
      });
    },
    // 修改每页数量
    handleSizeChange(val) {
      this.pagination.pageNum = 1;
      this.pagination.pageSize = val;
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = val;
      this.getTableData();
    },
    // 切换页面
    handleCurrentChange(val) {
      console.log(val)
      this.pagination.pageNum = val;
      this.searchForm.pageNum = val
      this.getTableData();
    },
    // 当列是选项时，获取对应值
    getOptionsValue(value, options) {
      return options.find((item) => item.value == value).label;
    },
  }
}
</script>

<style lang="scss" scoped>

/* 隐藏大图 */
img[v-else] {
  opacity: 0;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.table {
  margin: 0 20px;
  width: auto;
}

::v-deep thead tr {
  background: #91d4f8;
}

::v-deep .el-table th.el-table__cell {
  color: #444;
  background: #91d4f8;
}

::v-deep .text {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  white-space: nowrap; /* 防止换行 */
}

.empty {
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #666666;
  line-height: 33px;

  img {
    margin-bottom: 20px;
    width: 136px;
    height: 88px;
  }
}

</style>
