import request from "@/utils/request";
function getUrl(url) {
    return "/shop/" + url;
}

export default {
    saveUser(data){
        return request({
            url: getUrl("authUser/saveUser"),
            // url: "/shop/authUser/saveUser",
            method: "post",
            data,
        })
    },
    deleteUser(data){
        return request({
            url: getUrl("authUser/deleteUser"),
            method: "post",
            data,
        })
    },
    getUserPage(data){
        return request({
            url: getUrl("authUser/getUserPage"),
            method: "post",
            data,
        })
    },
    getUserList(data){
        return request({
            url: getUrl("authUser/getUserList"),
            method: "post",
            data,
        })
    },
    getUserVo(data){
        return request({
            url: getUrl("authUser/getUserVo"),
            method: "post",
            data,
        })
    }
}
