import request from "@/utils/request";

function getUrl(url) {
    return "/shop-test/" + url;
}

export default {
    listPage(data) {
        return request({
            url: getUrl("goods/tag/listPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("goods/tag/del"),
            method: "post",
            data,
        })
    },
    save(data) {
        return request({
            url: getUrl("goods/tag/save"),
            method: "post",
            data,
        })
    }, add(data) {
        return request({
            url: getUrl("goods/tag/add"),
            method: "post",
            data,
        })
    },
}
