import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("attrGroup/list/" + data.catId),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data
        })
    },
    save(data) {
        return request({
            url: getUrl("attrGroup/save"),
            method: "post",
            data
        })
    },
    update(data) {
        return request({
            url: getUrl("attrGroup/update"),
            method: "post",
            data
        })
    },
    del(data) {
        return request({
            url: getUrl("attrGroup/delete"),
            method: "post",
            data
        })
    },
    info(data) {
        return request({
            url: getUrl("attrGroup/info/" + data),
            method: "post",
        })
    },
    attrGroupAttrRelation(data) {
        return request({
            url: getUrl("attrGroup/" + data + "/attr/relation"),
            method: "get",
        })
    },
    noAttrGroupAttrRelation(data) {
        return request({
            url: getUrl("attrGroup/" + data.attrGroupId + "/noattr/relation"),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "post",
        })
    },
    relationDelete(data){
        return request({
            url: getUrl("attrGroup/attr/relation/delete"),
            method: "post",
            data
        })
    },
    attrRelation(data){
        return request({
            url: getUrl("attrGroup/attr/relation"),
            method: "post",
            data
        })
    },
    attrRelationDel(data){
        return request({
            url: getUrl("attrGroup/attr/relation/delete"),
            method: "post",
            data
        })
    },
    catelogWithattr(data){
        return request({
            url: getUrl("attrGroup/"+data.catelogId+"/withattr"),
            method: "post",
            data
        })
    }

}
