import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    obtainTotalBalanceUserStatistics(data) {
        return request({
            url: getUrl("home/obtainTotalBalanceUserStatistics"),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "post",
            data,
        })
    },
    getColorBlockData(data) {
        return request({
            url: getUrl("home/getColorBlockData"),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "post",
            data,
        })
    },
    getLeftChartData(data) {
        return request({
            url: getUrl("home/getLeftChartData"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    totalQuantityBeverageOrders(data) {
        return request({
            url: getUrl("home/totalQuantityBeverageOrders"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getRightChartData(data) {
        return request({
            url: getUrl("home/getRightChartData"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getCategorySalesTop5(data) {
        return request({
            url: getUrl("home/getCategorySalesTop5"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getPriceRangeSalesVolume(data) {
        return request({
            url: getUrl("home/getPriceRangeSalesVolume"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getTimeIntervalSalesVolume(data) {
        return request({
            url: getUrl("home/getTimeIntervalSalesVolume"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getFirstLevelClassificationViews(data) {
        return request({
            url: getUrl("home/getFirstLevelClassificationViews"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getOrderQuantity(data) {
        return request({
            url: getUrl("home/getOrderQuantity"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getSupplierDayLoad(data) {
        return request({
            url: getUrl("home/getSupplierDayLoad"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },

}
