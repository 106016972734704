import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    selectBalanceByMobile(data) {
        return request({
            url: getUrl("thirdParty/selectBalanceByMobile/" + data),
            method: "get",
            data,
        })
    },
    shoppingPay(data) {
        return request({
            url: getUrl("thirdParty/shoppingPay/" + data.phone + "/" + data.consumptionAmount),
            method: "get",
            data,
        })
    },
}
