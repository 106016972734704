import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("attr/"+data.attrType+"/list/" + data.catelogId),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data
        })
    },
    save(data) {
        return request({
            url: getUrl("attr/save"),
            method: "post",
            data
        })
    },
    update(data) {
        return request({
            url: getUrl("attr/update"),
            method: "post",
            data
        })
    },
    updateBySpuId(data,spuId) {
        return request({
            url: getUrl("attr/update/"+spuId),
            method: "post",
            data
        })
    },
    del(data) {
        return request({
            url: getUrl("attr/delete"),
            method: "post",
            data
        })
    },
    info(data) {
        return request({
            url: getUrl("attr/info/"+data),
            method: "post",
            data
        })
    },
    listforspu(data) {
        return request({
            url: getUrl("attr/base/listforspu/"+data),
            method: "get",
            data
        })
    },
}
