import request from "@/utils/request";
function getUrl(url) {
    return "/user-auth/" + url;
}

export default {
    getRole(data){
        return request({
            url: getUrl("menu/getRole"),
            method: "post",
            data,
        })
    },
}