import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("beverage/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("beverage/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("beverage/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("beverage/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("beverage/getInfo/"+data),
            method: "get",
        })
    },
    listByMachine(data) {
        return request({
            url: getUrl("beverage/listByMachine/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("beverage/listPage"),
            method: "post",
            data,
        })
    },
    getPage(data) {
        return request({
            url: getUrl("beverage/getPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    getPageByMachine(data) {
        return request({
            url: getUrl("beverage/getPageByMachine"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    all(data) {
        return request({
            url: getUrl("beverage/all"),
            method: "post",
            data,
        })
    },
    allByMachineId(data) {
        return request({
            url: getUrl("beverage/allByMachineId"),
            method: "post",
            data,
        })
    },
}
