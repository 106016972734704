import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("orderItem/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("orderItem/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("orderItem/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("orderItem/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("orderItem/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("orderItem/listPage"),
            method: "post",
            data,
        })
    },
    bindLogistics(data) {
        return request({
            url: getUrl("orderItem/bindLogistics"),
            method: "post",
            data,
        })
    },
    refund(data) {
        return request({
            url: getUrl("orderItem/refund"),
            method: "post",
            data,
        })
    }

}
