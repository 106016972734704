import request from "@/utils/request";
function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    save(data) {
        return request({
            url: getUrl("config/save"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("config/getInfo/"+data),
            method: "get"
        })
    },
    saveJson(data) {
        return request({
            url: getUrl("config/saveJson"),
            method: "post",
            data,
        })
    },
}
