import request from "@/utils/request";
function getUrl(url) {
    return "/user-auth/" + url;
}

export default {
    saveUser(data){
        return request({
            url: getUrl("user/saveUser"),
            method: "post",
            data,
        })
    },
    deleteUser(data){
        return request({
            url: getUrl("user/deleteUser"),
            method: "post",
            data,
        })
    },
    getUserPage(data){
        return request({
            url: getUrl("user/getUserPage"),
            method: "post",
            data,
        })
    },
    getUserList(data){
        return request({
            url: getUrl("user/getUserList"),
            method: "post",
            data,
        })
    },
    getUserVo(data){
        return request({
            url: getUrl("user/getUserVo"),
            method: "post",
            data,
        })
    }
}
