import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    listPage(data) {
        return request({
            url: getUrl("beverage/machine/way/cpfr/listPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    confirm(data) {
        return request({
            url: getUrl("beverage/machine/way/cpfr/confirm"),
            method: "post",
            data,
        })
    },
    batchConfirm(data) {
        return request({
            url: getUrl("beverage/machine/way/cpfr/batchConfirm"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("beverage/machine/way/cpfr/del"),
            method: "post",
            data,
        })
    },
}
