<template>
    <div class="navbar">
        <breadcrumb style="margin-left: 20px;" id="breadcrumb-container" class="breadcrumb-container"/>
        <div class="right-menu" style="overflow: hidden">
            <template>
                <!--                <screenfull id="screenfull" class="right-menu-item hover-effect"/>-->
            </template>
            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <span class="el-dropdown-link">
                      <img class="avatar" :src="user.avatar?user.avatar:avatar">
                        <!--                       {{ user.realName }}-->
                    </span>
                    <i class="el-icon-caret-bottom"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!--          <router-link to="/common/upPassWord">-->
                    <!--            <el-dropdown-item>修改密码</el-dropdown-item>-->
                    <!--          </router-link>-->
                    <el-dropdown-item @click="toProfile">
                        <!--                        <span style="display:block;">个人中心</span>-->
                        <router-link to="/system/user/profile">
                            <el-dropdown-item>我的</el-dropdown-item>
                        </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item @click.native="passwordModificationFlag = true">
                        <!-- <span style="display:block;">个人中心</span> -->
                        <router-link to="">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item @click.native="logout">
                        <!--                        <span style="display:block;">退出登录</span>-->
                        <router-link to="">
                            <el-dropdown-item>退出登录</el-dropdown-item>
                        </router-link>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <div>
            <el-dialog custom-class="transparent-dialog" :visible.sync="passwordModificationFlag" width="20%"
                       :modal="true"
                       :modal-append-to-body="false" :before-close="passwordModificationClose">
                <el-form :model="passwordForm" status-icon :rules="formRules" ref="formRules" class="demo-ruleForm">
                    <el-form-item label="原密码" prop="oldPassword">
                        <el-input type="password" v-model="passwordForm.oldPassword" autocomplete="off"
                                  style="width: 100%;"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input type="password" v-model="passwordForm.newPassword" autocomplete="off"
                                  style="width: 100%;"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="margin-top: -20px">
                    <!--              <el-button @click="passwordModificationFlag = false">取 消</el-button>-->
                    <!--              <el-button type="primary" @click="passwordModification">确 定</el-button>-->
                    <el-button @click="passwordModificationFlag = false">取消</el-button>
                    <el-button type="primary" @click="passwordModification('formRules')">提交</el-button>
                </div>
            </el-dialog>
        </div>


    </div>
</template>

<script>
    import store from '../../../store'
    import {mapGetters} from 'vuex'
    import Breadcrumb from '@/components/Breadcrumb/index.vue'
    import {changePassword} from "@/api/auth";
    export default {
        components: {
            Breadcrumb,
        },
        name: "EcareHeader",
        data() {
            return {
                userInfo: {},
                title: "电信消费平台",
                avatvr: require('@/assets/img/dxlogo.jpg'),
                passwordModificationFlag: false,
                passwordForm: {
                    oldPassword: '',
                    newPassword: '',
                },
                formRules: {
                    oldPassword: {required: true, message: '请输入原密码', trigger: 'blur'},
                    newPassword: {required: true, message: '请输入新密码', trigger: 'blur'},
                }
            }
        },
        created() {
            // 从本地存储获取用户信息
            // var user = JSON.parse(localStorage.getItem('userInfo'));
            // this.userInfo = user;
            console.log("localStorage用户详情信息", this.user)
        },
        computed: {
            ...mapGetters([
                'user',
            ])
        },
        methods: {
            passwordModificationClose() {
                if (this.passwordForm.newPassword || this.passwordForm.oldPassword) {
                    this.$confirm('密码还没进行修改,是否取消修改?').then(_ => {
                        this.passwordModificationFlag = false
                        this.passwordForm = {}
                    }).catch(_ => {
                        this.passwordModificationFlag = true
                    });
                } else {
                    this.passwordModificationFlag = false
                }
            },
            passwordModification(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.passwordForm.uuId = this.user.uuId;
                        console.log(this.passwordForm)
                        changePassword(this.passwordForm).then(res => {
                            this.$notify.success({
                                title: '成功',
                                message: "密码修改成功"
                            });
                            this.logout();
                        }).catch(error => {
                            this.$notify.error({
                                title: '错误',
                                message: error.message
                            });
                        })
                    } else {
                        return false;
                    }
                });
            },
            logout() {
                this.$store.dispatch('user/logout')
                this.$router.push(`/login?redirect=${this.$route.fullPath}`)
                // //移除Token
                // removeToken();
                // //刷新
                // location.reload();
            },
            toProfile() {
                store.dispatch('user/getInfo').then(res => {
                })
                this.$router.push(`/system/user/profile?redirect=${this.$route.fullPath}`)
            },
            toggleSideBar() {
                this.$store.dispatch('app/toggleSideBar')
            },
        }
    }
</script>

<style lang="scss" scoped>

  .avatar {
    border-radius: 30px;
    width: 40px;
    height: 40px;
    margin-bottom: 30px;
  }

  .navbar {
    height: 50px;
    margin-top: 10px;
    overflow: auto;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

    .hamburger-container {
      //line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }

    .breadcrumb-container {
      float: left;
    }

    .errLog-container {
      display: inline-block;
      vertical-align: top;
    }

    .right-menu {
      float: right;
      height: 100%;
      line-height: 50px;

      &:focus {
        outline: none;
      }

      .right-menu-item {
        display: inline-block;
        padding: 5px 8px;
        height: 100%;
        font-size: 15px;
        color: #5a5e66;
        vertical-align: text-bottom;

        &.hover-effect {
          cursor: pointer;
          transition: background .3s;

          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }

      .avatar-container {
        //margin-right: 20px;
        overflow: hidden;

        .avatar-wrapper {
          //margin-top: 5px;
          position: relative;

          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            font-size: 33px;
            border-radius: 10px;
          }

          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            //right: -20px;
            margin-left: 80px;
            margin-top: 15px;
            font-size: 12px;
          }
        }
      }
    }
  }
</style>
