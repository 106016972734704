import router from "./router";
import NProgress from 'nprogress' // progress bar
import store from './store'
import 'nprogress/nprogress.css' // progress bar style
import {getToken} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import {getSupplierID} from "@/api/supplier";
// import {getOption} from "./api/common";
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import {mapGetters} from "vuex";
NProgress.configure({showSpinner: false}) // NProgress Configuration

const whiteList = ['/login', '/401'] // no redirect whitelist


router.beforeEach(async (to, from, next) => {
    NProgress.start()
    document.title = getPageTitle(to.meta.title)
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            next({path: '/'})
            NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
        } else {
            const user = store.getters.user.uuId
            // console.log("store=>User", user);
            if (user) {
                if(store.getters.user.status == 1){
                    Notification.error({
                        title: '错误',
                        message: "账户已被禁用"
                    })
                    await store.dispatch('user/logout')
                    next(`/login?redirect=${to.path}`)
                }else {
                    next()
                }
            } else {
                try {
                    await store.dispatch('user/getInfo').then(res => {})
                    if(store.getters.user.status == 1){
                        Notification.error({
                            title: '错误',
                            message: "账户已被禁用"
                        })
                        await store.dispatch('user/logout')
                        next(`/login?redirect=${to.path}`)
                    }else {
                        next({...to, replace: true})
                    }
                } catch (error) {
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.fullPath}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
