import request from "@/utils/request";
import {getToken} from "@/utils/auth";

function getUrl(url) {
     return "/shop-test/" + url;
}

const uploadExcel = getUrl("excel/import")
export {uploadExcel}

export default {
    import(data){
        return request({
            url: getUrl("excel/import"),
            method: "post",
            data,
            headers: {
                "Content-Type": "multipart/form-data;boundary=" + new Date().getTime()
            },
        })
    },
    export(){
        return request({
            url: getUrl("excel/export"),
            method: "get",
            responseType: "blob",
            headers: {
                'UchungToken': getToken()
            }
        })
    }
}
