import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("beverage/order/list"),
            method: "post",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("beverage/order/add"),
            method: "post",
            data,
        })
    },
    bindLogistics(data) {
        return request({
            url: getUrl("beverage/order/bindLogistics"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("beverage/order/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("beverage/order/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("beverage/order/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("beverage/order/getListPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    agreeToRefund(data) {
        return request({
            url: getUrl("beverage/order/agreeToRefund"),
            method: "post",
            data,
        })
    },
    refund(data) {
        return request({
            url: getUrl("/beverage/order/refund"),
            method: "post",
            data,
        })
    },
    errorExamineADocument(data) {
        return request({
            url: getUrl("/beverage/order/errorExamineADocument"),
            method: "post",
            data,
        })
    },
    nullBeverageOrder(data) {
        return request({
            url: getUrl("/beverage/order/nullBeverageOrder/"+data),
            method: "post"
        })
    },
    orderVideoPull(data) {
        return request({
            url: getUrl("/beverage/order/orderVideoPull/"+data),
            method: "post"
        })
    }
}
