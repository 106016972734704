<template>
    <div style="background-color: #202033">
        <template v-for="value in this.menuData">
            <el-submenu :index="value.path || ''" v-if="value.children &&!value.hidden">
                <template slot="title">
                    <img :src="value.icon" style=" width: 15px;height: 15px"/>
                    <span style="margin-left: 8px" slot="title">{{ value.name }}</span>
                </template>
                <RecursiveMenu :menuData="value.children"></RecursiveMenu>
            </el-submenu>
            <el-menu-item :index="value.path" v-else-if="!value.hidden">
                    <img :src="value.icon" style=" width: 15px;height: 15px"/>
                    <span style="margin-left: 8px" slot="title">{{ value.name }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
    export default {
        props: ['menuData'],
        name: 'RecursiveMenu',
        mounted() {
            setTimeout(() => {
                // console.log("menuData", this.menuData)
            }, 2000)
        }
    }
</script>
