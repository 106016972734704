import request from "@/utils/request";

function getUrl(url) {
    return "/shop-test/" + url;
}

export default {
    listPage(data) {
        return request({
            url: getUrl("site/notice/listPage"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
    },
    save(data) {
        return request({
            url: getUrl("site/notice/save"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("site/notice/del"),
            method: "post",
            data,
        })
    },
    updateStatus(data) {
        return request({
            url: getUrl("site/notice/updateStatus"),
            method: "post",
            data,
        })
    }
}
