import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'; // 引入中文语言包
dayjs.locale('zh-cn'); // 设置 dayjs 使用中文本地化

export function dateFormat(val){
    if (val!=null){
       let  date =dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function getWeekday(val) {
    if (val != null) {
        let date = dayjs(val);
        if (date.isSame('2099-01-01')) {
            return "长期";
        }
        return date.format('dddd'); // 'dddd' 表示返回完整的星期几字符串
    } else {
        return "暂无数据";
    }
}


export function yyyyMMdd(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function yyyyMM(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}


export function yyMMddHHmm(val){
    if (val!=null){
        let  date =dayjs(val).format('YY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}
export function yyMMdd(val){
    if (val!=null){
        let  date =dayjs(val).format('YY-MM-DD')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function yyMM(val){
    if (val!=null){
        let  date =dayjs(val).format('YY-MM')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function dateFormat2(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function clickInDateFormat(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function birthDateFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD')
    }else {
        return "未填写"
    }
}

export function birthDateTimeFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD HH:mm')
    }else {
        return "-"
    }
}

export function sexType(value) {
    let sex = "";
    switch (value){
        case 0:
            sex = '男';
            break;
        case 1:
            sex = '女';
            break;
        default:
            sex = '未知';
            break;
    }
    return sex;
}

export function couponType(value) {
    let coupon = "";
    switch (value){
        case 0:
            coupon = '满减';
            break;
        case 1:
            coupon = '立减';
            break;
        default:
            coupon = '折扣';
            break;
    }
    return coupon;
}

export function activityStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '启用';
            break;
        case 1:
            state = '未启用';
            break;
        default:
            state = '已过期';
            break;
    }
    return state;
}

export function payType(value) {
    let type = "-";
    switch (value) {
        case 'WX_PAY':
            type = '微信支付';
            break;
        case 'MEAL_CARD_PAY':
            type = '餐卡支付';
            break;
        case 'INTEGRAL_PAY':
            type = '积分支付';
            break;
        case 'SHOPPING_GOLD_PAY':
            type = '购物金支付';
            break;
        default:
            type = '未知';
            break;
    }
    return type;
}

export function wayState(value) {
    // 货道状态 0未启用 1正常 2开路故障 3短路故障 4检测机故障 5电路故障 6超时故障 7货道正忙
    let type = "-";
    switch (value) {
        case 0:
            type = '未启用';
            break;
        case 1:
            type = '正常';
            break;
        case 2:
            type = '开路故障';
            break;
        case 3:
            type = '短路故障';
            break;
        case 4:
            type = '检测机故障';
            break;
        case 5:
            type = '电路故障';
            break;
        case 6:
            type = '超时故障';
            break;
        case 7:
            type = '货道正忙';
            break;
        default:
            type = '未知';
            break;
    }
    return type;
}

export function noticeWay(value) {
    let state = "";
    switch (value){
        case 0:
            state = '系统/站内信';
            break;
        case 1:
            state = '短信';
            break;
        case 2:
            state = '模版';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function noticeType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '下单';
            break;
        case 1:
            state = '发货';
            break;
        case 2:
            state = '系统通知';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function noticeStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '未读';
            break;
        case 1:
            state = '已读';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function cpfrType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '后台补货';
            break;
        case 1:
            state = '商家端补货';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function cpfrStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '提交';
            break;
        case 1:
            state = '待审核';
            break;
        case 2:
            state = '审核通过';
            break;
        case 3:
            state = '审核未通过';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function cpfrUserType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '管理员';
            break;
        case 1:
            state = '供应商';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function autoUserType(value) {
    let state = "";
    switch (value){
        case 999:
            state = '管理员';
            break;
        case 888:
            state = '供应商';
            break;
        case 777:
            state = '电信管理员';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function recodeUserType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '会员';
            break;
        case 1:
            state = '供应商';
            break;
        case 2:
            state = '管理员';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function recodeType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '短信';
            break;
        case 1:
            state = '微信模版信息';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function XOrderType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '商品订单';
            break;
        case 1:
            state = '饮料订单';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export default {
    install(Vue) {
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('birthDateFormat', birthDateFormat)
        Vue.filter('clickInDateFormat', clickInDateFormat)
        Vue.filter('sexType', sexType)
        Vue.filter('couponType', couponType)
        Vue.filter('activityStatus', activityStatus)
        Vue.filter('payType', payType)
        Vue.filter('dateFormat2', dateFormat2)
        Vue.filter('wayState', wayState)
        Vue.filter('noticeWay', noticeWay)
        Vue.filter('noticeType', noticeType)
        Vue.filter('noticeStatus', noticeStatus)
        Vue.filter('cpfrType', cpfrType)
        Vue.filter('cpfrStatus', cpfrStatus)
        Vue.filter('cpfrUserType', cpfrUserType)
        Vue.filter('autoUserType', autoUserType)
        Vue.filter('recodeType', recodeType)
        Vue.filter('recodeUserType', recodeUserType)
        Vue.filter('XOrderType', XOrderType)
    }
}
