import request from "@/utils/request";

function getUrl(url) {
     return "/shop-test/" + url;
}

export default {
    list(data) {
        return request({
            url: getUrl("supplier/user/list"),
            method: "post",
            data,
        })
    },

    add(data) {
        return request({
            url: getUrl("supplier/user/add"),
            method: "post",
            data,
        })
    },

    update(data) {
        return request({
            url: getUrl("supplier/user/update"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("supplier/user/del"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("supplier/user/getInfo/"+data),
            method: "get",
        })
    },
    getInfoByUUID(data) {
        return request({
            url: getUrl("supplier/user/getInfoByUUID/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("supplier/user/listPage"),
            method: "post",
            data,
        })
    },

}
